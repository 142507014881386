<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-layout class="rounded-xl overflow-hidden mx-10 mb-3" column>
    <v-toolbar class="primary" tag="div" flat dark tile>
      <v-tooltip transition="fade-transition" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="setToday" icon dark v-bind="attrs" v-on="on">
            <v-icon>mdi-calendar-today</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("labeltoday") }}</span>
      </v-tooltip>
      <v-divider class="mx-1" vertical />

      <create-reminder @updateReminder="getReminder" />

      <v-divider class="mx-1" vertical />

      {{/* Filter */}}
      <div class="text-body-2 mr-2 font-weight-bold">Filters</div>
      <template v-if="admins">
        <v-autocomplete
          v-model="filterRequest.toEmployeeId"
          label="Employees"
          :items="employees"
          :loading="loadingEmployees"
          :filter="
            (item, queryText) => {
              return item.fullname
                .toLowerCase()
                .includes(queryText.toLowerCase());
            }
          "
          item-text="fullname"
          item-value="uuid"
          autocomplete="off"
          clearable
          hide-details
          outlined
          dense
          @change="getReminder"
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title
                v-html="data.item.fullname"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-if="data.item.ocupation"
                class="text-capitalize"
                v-html="data.item.ocupation.name"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </template>

      <filter-by-lead
        :name="'Lead'"
        @selectC="filteringLead($event)"
        class="mx-1"
      />
      <filter-by-clients
        @selectC="filteringPatient($event)"
        :name="getpatientN + 's'"
      />

      <v-divider class="mx-1" vertical />

      {{/* Date navigation */}}
      <v-layout align-center mr-14>
        <v-btn icon dark @click="$refs.calendar.prev()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon dark @click="$refs.calendar.next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <span class="font-weight-bold white--text mx-2">{{ labelDate }}</span>
      </v-layout>

      <v-spacer />
      {{/* Calendar type selector*/}}
      <v-btn-toggle
        class="hidden-md-and-down ml-4"
        v-model="type"
        background-color="transparent"
        mandatory
        borderless
        tile
      >
        <v-btn
          v-for="(typeItem, index) in types"
          :key="index"
          :value="typeItem.value"
          class="rounded-lg"
          dark
          text
        >
          <div class="flex-column align-center justify-center">
            <v-icon color="white">{{ typeItem.icon }}</v-icon>
            <div
              class="text-caption text-capitalize white--text font-weight-bold"
            >
              {{ $t(typeItem.label) }}
            </div>
          </div>
        </v-btn>
      </v-btn-toggle>
      <v-select
        v-model="type"
        :items="types"
        item-text="label"
        dense
        outlined
        hide-details
        class="ml-3 hidden-lg-and-up"
        label="Type"
        @change="getReminder"
      ></v-select>

      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="deep-purple accent-4"
      />
    </v-toolbar>

    {{/* The calendar */}}
    <v-card
      class="crm-calendar fill-height"
      tile
      color="white"
      :loading="loading"
    >
      <v-calendar
        class="mb-5"
        ref="calendar"
        v-model="date"
        :weekdays="weekday"
        :type="type"
        :events="arrayCalendar"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        color="primary"
        @moved="setCurrentDate"
        @click:event="showEvent"
        @click:date="showDay"
        @change="onChange"
      >
        <template v-slot:event="{ event }">
          <v-tooltip transition="fade-transition" top>
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex events ml-1" v-on="on" v-bind="attrs">
                <div>{{ event.name.split(" ")[0] }}</div>
              </div>
            </template>
            <span v-html="eventSummary(event)"></span>
          </v-tooltip>
        </template>
      </v-calendar>
    </v-card>

    <v-dialog
      v-model="openDetails"
      persistent
      :overlay="false"
      max-width="650px"
      transition="dialog-transition"
    >
      <v-card>
        <template v-if="currentEvent">
          <v-card-title
            :class="
              currentEvent.typeE == 'document'
                ? ' headline secondary  align-center white--text  justify-space-between'
                : currentEvent.data.aboutPatient
                ? ' headline success  align-center white--text justify-space-between'
                : currentEvent.data.aboutLead
                ? ' headline info  align-center white--text justify-space-between'
                : ' headline primary  align-center white--text justify-space-between'
            "
          >
            {{
              currentEvent.typeE == "document"
                ? "Document"
                : currentEvent.data.aboutPatient
                ? $t("reminder") + " (Client)"
                : currentEvent.data.aboutLead
                ? $t("reminder") + " (Lead)"
                : $t("reminder")
            }}
            <template v-if="currentEvent.typeE != 'document'">
              <div>
                {{ "Status: " }}
                {{ currentEvent ? currentEvent.data.status : "" }}
              </div>
            </template>
          </v-card-title>
          <v-card-text class="my-4">
            <template v-if="currentEvent">
              <template v-if="currentEvent.typeE == 'document'">
                <v-row>
                  <v-col cols="12" sm="4">
                    <div class="font-weight-bold">Due Date:</div>
                    <div>{{ getDate(currentEvent.data.dueDate) }}</div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div class="font-weight-bold">Client:</div>
                    <div>{{ currentEvent.name }}</div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          fab
                          small
                          color="success"
                          @click="gotoPatient(currentEvent.data.patient)"
                          ><v-icon>mdi-account</v-icon></v-btn
                        >
                      </template>
                      <span>Go to Client</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row>
                  <v-col cols="12" sm="4">
                    <div class="font-weight-bold">Created At:</div>
                    <div>{{ getDate(currentEvent.data.createdAt) }}</div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div class="font-weight-bold">Created By:</div>
                    <div>{{ currentEvent.data.createdBy.fullname }}</div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div class="font-weight-bold">Ways:</div>
                    <div class="d-flex">
                      <v-chip
                        small
                        :class="i != 0 ? 'mx-2' : ''"
                        v-for="(w, i) in currentEvent.data.way"
                        :key="w"
                      >
                        {{ w }}
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <div class="font-weight-bold">Execute At:</div>
                    <div>{{ getDateH(currentEvent.data.executeAt) }}</div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div class="font-weight-bold">
                      {{
                        currentEvent.data.aboutPatient ||
                        currentEvent.data.aboutLead
                          ? "About:"
                          : "To:"
                      }}
                    </div>
                    <div>
                      {{
                        currentEvent.data.aboutPatient
                          ? "Client"
                          : currentEvent.data.aboutLead
                          ? "Lead"
                          : currentEvent.data.toEmployee
                          ? "Employee"
                          : currentEvent.data.toLead
                          ? "Lead"
                          : currentEvent.data.toPatient
                          ? "Client"
                          : "-"
                      }}
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4  ">
                    <div class="font-weight-bold">Name:</div>
                    <div>
                      {{
                        currentEvent.data.aboutPatient
                          ? currentEvent.data.aboutPatient.fullname
                          : currentEvent.data.aboutLead
                          ? currentEvent.data.aboutLead.name +
                            " " +
                            currentEvent.data.aboutLead.last_name
                          : currentEvent.data.toEmployee
                          ? currentEvent.data.toEmployee.fullname
                          : currentEvent.data.toPatient
                          ? currentEvent.data.toPatient.fullname
                          : currentEvent.data.toLead
                          ? currentEvent.data.toLead.fullname
                          : "-"
                      }}
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="font-weight-bold">Content:</div>
                    <div>{{ currentEvent.data.content }}</div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <div class="font-weight-bold">Go to:</div>
                    <template v-if="currentEvent.data.aboutPatient">
                      <v-btn
                        color="success"
                        text
                        @click="gotoPatient(currentEvent.data.aboutPatient)"
                        ><v-icon>mdi-account</v-icon> {{ "Client" }}</v-btn
                      >
                    </template>
                    <template v-if="currentEvent.data.toPatient">
                      <v-btn
                        color="success"
                        text
                        @click="gotoPatient(currentEvent.data.toPatient)"
                        ><v-icon>mdi-account</v-icon> {{ "Client" }}</v-btn
                      >
                    </template>
                    <template v-if="currentEvent.data.aboutLead">
                      <v-btn
                        color="info"
                        text
                        @click="gotoLead(currentEvent.data.aboutLead)"
                        ><v-icon>mdi-account</v-icon> {{ "Lead" }}</v-btn
                      >
                    </template>
                    <template v-if="currentEvent.data.toLead">
                      <v-btn
                        color="info"
                        text
                        @click="gotoLead(currentEvent.data.toLead)"
                        ><v-icon>mdi-account</v-icon> {{ "Lead" }}</v-btn
                      >
                    </template>
                    <template v-if="currentEvent.data.toEmployee">
                      <v-btn
                        color="cyan"
                        text
                        @click="gotoEmployee(currentEvent.data.toEmployee)"
                        ><v-icon>mdi-account</v-icon> {{ "Employee" }}</v-btn
                      >
                    </template>
                  </v-col>
                </v-row>
              </template>
            </template>
          </v-card-text>
        </template>

        <v-card-actions class="d-flex">
          <v-btn color="error" text @click="toDeleteReminder"
            ><v-icon>mdi-delete</v-icon> Delete</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="closeEvent">close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelte"
      persistent
      :overlay="false"
      max-width="400px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="error white--text"> Delete Reminder </v-card-title>
        <v-card-text class="mt-4 text-center">
          <h4>Are you sure you want delete this reminder?</h4>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="cancelDelete" :disabled="loading" text>Cancel</v-btn>
          <v-btn
            color="error"
            text
            :loading="loading"
            :disabled="loading"
            @click="confirmDelte"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
//import EventViewer from "../EventViewer.vue";
/* const BtnAddAppointment = () =>
  import(
    "../buttons/BtnAddAppointment.vue"
  ); */

import { MonthItem, monthsNames } from "@/utils/monthsNames";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import timeHhMm from "@/utils/timeHhMm";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import MaTimePicker from "@/components/MaTimePicker.vue";
//import "./style.scss";

import moment from "moment";
// import { getAPI } from "@/api/axios-base";
import { RolLabel } from "@/utils/rolesStatus";
import { getAPI } from "@/api/axios-base";

import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { utilMixin } from "@/utils/mixins";
import CreateReminder from "./CreateReminder.vue";
import FilterByClients from "@/components/filters/FilterByClients.vue";
import FilterByLead from "@/components/filters/FilterByLead.vue";
export default Vue.extend({
  name: "crm-notifications-calendar",
  components: {
    // EventViewer,
    //  BtnAddAppointment,
    MaDatePicker,
    MaTimePicker,
    CreateReminder,
    FilterByClients,
    FilterByLead,
  },
  mixins: [utilMixin],
  data() {
    return {
      type: "month",
      types: [
        {
          label: this.$t("labelMonth"),
          value: "month",
          icon: "mdi-calendar-month",
        },
        {
          label: this.$t("labelWeek"),
          value: "week",
          icon: "mdi-calendar-week",
        },
        {
          label: this.$t("labelDay"),
          value: "day",
          icon: "mdi-calendar-text",
        },
        {
          label: this.$t("label4Day"),
          value: "4day",
          icon: "mdi-calendar-range",
        },
      ],
      mode: "column",
      modes: ["stack", "column"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      date: "",
      events: [],
      admins: false,
      year: 0,
      month: "",
      // For drag & drop
      dragEvent: null,
      dragStart: null,
      dragTime: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      // Event details
      openDetails: false,
      currentEvent: null,
      //Delete Reminder
      dialogDelte: false,
      //filters
      filterRequest: {
        dateInterval: {
          date1: "",
          date2: "",
        },
        offset: 0,
        limit: 1000,
        status: "PENDING",
        toEmployeeId: null,
        toLeadId: null,
        toPatientId: null,
        way: null,
      },

      selectedEvent: {},
      selectedElement: null,
      RolLabel: RolLabel,
      // Event details on hover
      loading: false,
      event: null,
      patient: null,
      loadingPatientData: false,
      reminderFiltered: [],
      documentFiltered: [],
    };
  },
  computed: {
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "isSupervisor",
      "getpatientN",
      "getUuid",
    ]),

    ...mapState("crmConfigurationsModule", ["leads"]),
    ...mapState("crmEmployeeModule", {
      loadingEmployees: "loading",
      employees: "workers",
    }),
    leadshow() {
      if (this.leads.length == 0) {
        return [];
      }
      const arr = this.leads.map((l) => {
        return { ...l, fullname: l.name + " " + l.last_name };
      });
      return arr;
    },
    arrayCalendar() {
      const res = this.reminderFiltered.concat(this.documentFiltered);
      return res;
    },

    labelDate() {
      return `${this.year} ${this.month}`;
    },

    validEmploye() {
      if (this.totypeSelect == "Employee") {
        if (this.toEmployeeId == null || this.toEmployeeId == "") {
          return false;
        }
        return true;
      }
      return true;
    },
    validLead() {
      if (this.totypeSelect == "Lead") {
        if (this.toLeadId == null || this.toLeadId == "") {
          return false;
        }
        return true;
      }
      return true;
    },
    validPatient() {
      if (this.totypeSelect == "Client") {
        if (this.toPatientId == null || this.toPatientId == "") {
          return false;
        }
        return true;
      }
      return true;
    },

    validAdd() {
      if (
        this.executeTime != null &&
        this.executeDate != null &&
        this.way.length != 0 &&
        this.content != null &&
        this.content != "" &&
        this.validPatient &&
        this.validEmploye &&
        this.validLead
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    // appointments(val: any[]) {
    //   (this as any).reminderFiltered = val;
    //   this.filterAppoinments(this.appointmetsType);
    // },
  },
  async mounted() {
    this.setToday();

    await this.actGetLeads({ interval: { limit: 1000, offset: 0 } });

    if (this.isAdmin || this.isSuper || this.isSupervisor) {
      this.admins = true;

      await this.actGetEmployees();
    } else {
      this.toEmployeeId = this.getUuid;
    }
  },
  methods: {
    ...mapActions("crmAppointmentsModule", [
      "actListAppointment",
      "actFilterAppointment",
    ]),
    ...mapMutations("crmAppointmentsModule", ["mutDate"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),

    ...mapActions("crmConfigurationsModule", ["actGetLeads"]),

    setCurrentDate({ month, year }) {
      this.year = year;
      this.month = monthsNames[month - 1].name;
    },
    clearData() {
      this.selectedElement = null;
      this.selectedEvent = {};
    },

    setToday() {
      const now = new Date();

      this.setCurrentDate(this.$refs.calendar.getNow());
      this.date = now.toLocaleDateString();
    },
    showDay() {
      this.type = "day";
    },
    createReminder(event) {
      this.createDialog = true;
    },
    filteringPatient(value) {
      this.filterRequest.toPatientId = value;
      this.getReminder();
    },
    filteringLead(value) {
      this.filterRequest.toLeadId = value;
      this.getReminder();
    },

    getReminder() {
      this.loading = true;
      let body = Object.assign({}, this.filterRequest);
      this.openDetails = false;
      body = this.cleanNull(body);

      getAPI
        .post("/reminder/filterlist", body)
        .then((res) => {
          this.loading = false;
          let temp = res.data.result;

          this.reminderFiltered = temp;

          this.reminderFiltered = this.reminderFiltered.map((app) => {
            const startt = moment(app.executeAt).format("YYYY-MM-DD HH:mm");
            const endt = moment(app.executeAt)
              .add(45, "minute")
              .format("YYYY-MM-DD HH:mm");
            return {
              name: app.aboutPatient
                ? app.aboutPatient.fullname
                : app.aboutLead
                ? app.aboutLead.name
                : app.toEmployee
                ? app.toEmployee.fullname
                : app.toPatient
                ? app.toPatient.fullname
                : app.toLead
                ? app.toLead.fullname
                : app.content,
              start: startt,
              typeE: "reminder",
              data: app,
              end: endt,
              color: app.aboutLead
                ? "info"
                : app.aboutPatient
                ? "success"
                : "primary",
            };
          });
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
      this.loading = true;
      getAPI
        .post("/client-documents/filter-list", {
          limit: this.filterRequest.limit,
          offset: this.filterRequest.offset,
          dateInterval: {
            date1: this.filterRequest.dateInterval.date1,
            date2: this.filterRequest.dateInterval.date2,
          },
        })
        .then((res) => {
          this.loading = false;
          const tempdocs = res.data.result;
          this.documentFiltered = tempdocs;
          this.documentFiltered = this.documentFiltered.map((app) => {
            const startt = moment(app.dueDate).format("YYYY-MM-DD HH:mm");
            const endt = moment(app.dueDate)
              .add(1, "hour")
              .format("YYYY-MM-DD HH:mm");

            return {
              name: app.patient ? app.patient.fullname : "",
              start: startt,
              data: app,
              typeE: "document",
              end: endt,
              color: "secondary",
            };
          });
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    async onChange({ start, end }) {
      this.filterRequest.dateInterval.date1 = moment(start.date)
        .startOf("day")
        .utc()
        .toISOString();
      this.filterRequest.dateInterval.date2 = moment(end.date)
        .endOf("day")
        .utc()
        .toISOString();
      this.reminderFiltered = [];
      await this.getReminder();
      this.mutDate(start.date);
    },

    eventSummary(event) {
      // ${moment(timeHhMm(event.start), "hh:mm").format("hh:mm a")}
      if (event.typeE == "document") {
        return `
      <b>${"Document"}</b> <br/>
      <b>To: ${event.data.patient ? event.data.patient.fullname : ""}</b> <br/>
      ${moment(timeHhMm(event.start), "hh:mm").format("hh:mm a")} to ${moment(
          timeHhMm(event.end),
          "hh:mm"
        ).format("hh:mm a")}`;
      }
      return `
      <b>${
        event.data.aboutPatient
          ? "Reminder (Client)"
          : event.data.aboutLead
          ? "Reminder (Lead)"
          : "Reminder"
      }</b> <br/>
      <b>To: ${
        event.data.aboutPatient
          ? event.data.aboutPatient.fullname
          : event.data.aboutLead
          ? event.data.aboutLead.name + " " + event.data.aboutLead.last_name
          : event.data.toEmployee
          ? event.data.toEmployee.fullname
          : event.data.toPatient
          ? event.data.toPatient.fullname
          : event.data.toLead
          ? event.data.toLead.fullname
          : event.data.content
      }</b> <br/>

      <b>Content: ${event.data.content}<b/> <br/>
      ${moment(timeHhMm(event.start), "hh:mm").format("hh:mm a")} to ${moment(
        timeHhMm(event.end),
        "hh:mm"
      ).format("hh:mm a")}`;
    },

    gotoPatient(patient) {
      this.$router.push({
        name: "Patient ddetails",
        params: { uuid: patient.uuid },
      });
    },
    gotoLead(lead) {
      this.$router.push(`/leads_details/${lead.uuid}`);
    },
    gotoEmployee(employee) {
      this.$router.push("/employees/details/" + employee.uuid);
    },

    showEvent(event) {
      this.openDetails = true;
      this.currentEvent = event.event;
    },
    closeEvent() {
      this.openDetails = false;
      this.currentEvent = null;
      this.dialogDelte = false;
    },

    toDeleteReminder() {
      this.dialogDelte = true;
    },
    cancelDelete() {
      this.dialogDelte = false;
    },
    confirmDelte() {
      this.loading = true;
      const uuid = this.currentEvent.data.uuid;
      getAPI
        .delete("/reminder/delete/" + uuid)
        .then(() => {
          this.loading = false;
          this.closeEvent();
          notifyInfo("Reminder has been delete");
          this.getReminder();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    cleanNull(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
});
</script>
<style lang="scss" scoped>
v-event {
  height: 25px;
  width: 80%;
  margin-bottom: 1px;
  margin-left: 3px;
  border: 3px solid #9c9393 !important;
  padding: 0 3px;
}
</style>
